import axios from "axios";
import React, { useEffect, useState } from "react";
import { GetSingleClientByDomain } from "../../api/api";
import AppLandingTheme from "./app-landing/AppLanding";
import PersonalPortfolioTheme from "./personal-portfolio/PersonalPortfolio";

const Home = () => {
  const [client, setClient]: any = useState([]);

  useEffect(() => {
    async function fetchClientData(domain: any) {
      try {
        // use this when hosting
        const response = await GetSingleClientByDomain(domain);

        //  use this when testing in local
        // const response = await GetSingleClientByDomain("kevin-2gu.pages.dev");

        console.log(response.data.data);

        const themeColor = response.data.data.theme_color;
        const numericColor = parseInt(themeColor.replace(/\D/g, ""));

        import(`../../Themes/assets/css/theme_${numericColor}.css`);

        setClient(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }

    const userDomain = window.location.hostname; // use this

    fetchClientData(userDomain);
  }, []);

  return (
    <>
      {client.theme_option === "app-landing" ? (
        <AppLandingTheme client={client} />
      ) : (
        ""
      )}
      {client.theme_option === "personal-porfolio" ? (
        <PersonalPortfolioTheme client={client} />
      ) : (
        ""
      )}
    </>
  );
};

export default Home;
