// import '../../assets/css/theme_4.css';
import LogoMini from "../../assets/img/personal-portfolio/logo_mini.svg";
import HeroImage from "../../assets/img/personal-portfolio/hero-img.png";
import Logo from "../../assets/img/personal-portfolio/logo.svg";
import Project1 from "../../assets/img/personal-portfolio/project1.jpg";
import React, { Component, RefObject, useRef, useState } from "react";
import endPoints from "../../../config/endPoints.config";

const PersonalPortfolio = (props: any) => {
  const { client } = props;
  console.log(client, "clientclientclient");

  const homeRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const serviceRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const scrollTo = (
    ref: React.RefObject<HTMLDivElement>,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  {
    /*Use this section for about variables and other functions */
  }
  const [about_active_tab_status, set_about_active_tab_status] =
    useState<any>("about");

  function onAboutClick(value: any) {
    set_about_active_tab_status(value);
  }

  return (
    <div>
      {/* Start Preloader */}
      {/* <div className="cs-preloader cs-accent_color cs-white_bg">
        <div className="cs-preloader_bg cs-center cs-accent_4_bg">
          <div className="cs-preloader_in cs-accent_15_border">
            <img src={LogoMini} alt="Logo" />
          </div>
        </div>
      </div> */}
      {/* End Preloader */}
      {/* Start Header Section */}
      <header className="cs-site_header cs-style1 cs-sticky-header cs-primary_font">
        <div className="cs-main_header">
          <div className="container">
            <div className="cs-main_header_in">
              <div className="cs-main_header_left">
                <a className="cs-site_branding" href="index.html">
                  <img src={Logo} alt="" />
                </a>
              </div>
              <div className="cs-main_header_right">
                <div className="cs-nav">
                  <ul className="cs-nav_list">
                    <li className="menu-item-has-children cs-mega-menu current-menu-item">
                      <a href="index.html">Home</a>
                      <ul className="cs-mega-wrapper">
                        <li className="menu-item-has-children">
                          <a href="#">Column One</a>
                          <ul>
                            <li>
                              <a href="../digital-agency/index.html">
                                Digital Agency
                              </a>
                            </li>
                            <li>
                              <a href="../marketing-agency/index.html">
                                Marketing Agency
                              </a>
                            </li>
                            <li>
                              <a href="../saas-landing/index.html">
                                SaaS Landing
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item-has-children">
                          <a href="#">Column Two</a>
                          <ul>
                            <li>
                              <a href="../personal-portfolio/index.html">
                                Personal Portfolio
                              </a>
                            </li>
                            <li>
                              <a href="../hosting/index.html">Hosting</a>
                            </li>
                            <li>
                              <a href="../app-landing/index.html">
                                App Landing
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item-has-children">
                          <a href="#">Column Three</a>
                          <ul>
                            <li>
                              <a href="../design-agency/index.html">
                                Design Agency
                              </a>
                            </li>
                            <li>
                              <a href="../online-education/index.html">
                                Online Education
                              </a>
                            </li>
                            <li>
                              <a href="../e-commerce/index.html">E-commerce</a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item-has-children">
                          <a href="#">Column Four</a>
                          <ul>
                            <li>
                              <a href="../restrurant/index.html">Restrurant</a>
                            </li>
                            <li>
                              <a href="../creative-agency/index.html">
                                Creative Agency
                              </a>
                            </li>
                            <li>
                              <a href="../event-conference/index.html">
                                Event Conference
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={(event) => scrollTo(aboutRef, event)}
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={(event) => scrollTo(serviceRef, event)}
                      >
                        Service
                      </a>
                    </li>
                    <li>
                      <a href="portfolio.html">Works</a>
                    </li>
                    <li>
                      <a href="blog.html">Blog</a>
                    </li>
                  </ul>
                </div>
                <div className="cs-toolbox">
                  <a
                    href="contact.html"
                    className="cs-toolbox_btn cs-accent_bg cs-white cs-accent_bg_2_hover cs-white_hover cs-rounded"
                  >
                    <span>Hire Me</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* End Header Section */}
      {/* Start Hero */}
      <div className="cs-hero cs-style4 cs-center cs-hobble">
        <div
          className="container wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="cs-hero_text">
            <div className="cs-hero_subtitle">FULL-STACK DEVELOPER</div>
            <h1 className="cs-hero_title">
              I’m <b className="cs-accent_color_2">{client?.name}</b> <br />a{" "}
              <b className="cs-accent_color">freelancer</b> <br />
              from USA.
            </h1>
            <div className="cs-hero_btns">
              <a
                href={client?.cv ? endPoints.pdfUploadFolder + client?.cv : ""}
                // download
                target="_blank"
                className="cs-btn cs-style2 cs-btn_lg cs-accent_bg cs-medium rounded-0 cs-primary_font"
              >
                View / Download My CV
              </a>
            </div>
          </div>
        </div>
        <div
          className="cs-hero_img_wrap cs-parallax wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        >
          <img className="cs-hero_img" src={HeroImage} alt="" />
          <div className="cs-hero_img_shape cs-accent_color_2 cs-to_right" />
          <div className="cs-hero_img_bg">
            <img
              src="../assets/img/personal-portfolio/hero-img-bg.svg"
              alt=""
            />
          </div>
          <div className="cs-hero_card cs-style1 cs-accent_color_2 cs-white_bg cs-semi_bold cs-to_right_up">
            <svg
              className="cs-round_percentage cs-round_effect"
              viewBox="0 0 100 100"
              width={70}
              height={70}
              data-percent={95}
            >
              <circle cx={50} cy={50} r={40} />
            </svg>
            <div className="cs-round_in cs-center">
              <span className="cs-bold cs-primary_color">95%</span>
            </div>
            <div className="cs-primary_color">
              Job <br />
              Success
            </div>
          </div>
          <div className="cs-hero_card cs-style2 cs-accent_color cs-white_bg cs-semi_bold cs-to_left_up">
            <div className="cs-round_in cs-center">
              <span className="cs-bold cs-primary_color">99+</span>
            </div>
            <div className="cs-primary_color">
              Happy <br />
              Clients
            </div>
            <svg
              className="cs-round_percentage cs-round_effect"
              viewBox="0 0 100 100"
              width={70}
              height={70}
              data-percent={100}
            >
              <circle cx={50} cy={50} r={40} />
            </svg>
          </div>
        </div>
      </div>
      {/* End Hero */}
      {/* Start About */}
      <div ref={aboutRef} className="cs-accent_5_bg_2">
        <div className="cs-height_140 cs-height_lg_80" />
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="cs-left_full_width text-end cs-space_right_105">
                <div className="cs-image_box cs-style4 cs-parallax cs-space55">
                  <img
                    className="cs-image_box_img"
                    src="../assets/img/personal-portfolio/about.png"
                    alt=""
                  />
                  <a
                    href="https://www.youtube.com/embed/jRcfE2xxSAw?autoplay=1"
                    className="cs-player_btn cs-video_open cs-center cs-to_up cs-accent_bg_2 cs-white cs-white_hover"
                  >
                    <i className="fas fa-play" />
                  </a>
                  <div className="cs-image_box_pattern cs-to_left cs-accent_color" />
                </div>
                <div className="cs-height_0 cs-height_lg_50" />
              </div>
            </div>
            {/* .col */}
            <div
              className="col-lg-6 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div className="cs-vertical_middle">
                <div className="cs-vertical_middle_in">
                  <div className="cs-tabs cs-fade_tabs cs-style1">
                    <div className="cs-medium">
                      <ul className="cs-tab_links cs-style3 cs-mp0 cs-primary_font">
                        <li
                          className={
                            about_active_tab_status === "about" ? "active" : ""
                          }
                        >
                          <a onClick={() => onAboutClick("about")}>About</a>
                        </li>
                        <li
                          className={
                            about_active_tab_status === "skills" ? "active" : ""
                          }
                        >
                          <a onClick={() => onAboutClick("skills")}>Skills</a>
                        </li>
                        <li
                          className={
                            about_active_tab_status === "education"
                              ? "active"
                              : ""
                          }
                        >
                          <a onClick={() => onAboutClick("education")}>
                            Education
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="cs-height_50 cs-height_lg_40" />
                    <div className="cs-tab_content">
                      <div
                        id="tab_one"
                        className={
                          about_active_tab_status === "about"
                            ? "cs-tab active"
                            : "cs-tab"
                        }
                      >
                        <div className="cs-text_box cs-style1 cs-size1">
                          <h2 className="cs-text_box_title cs-medium">
                            Why you should <br />
                            <b className="cs-bold cs-accent_color">hire me</b>
                          </h2>
                          <div className="cs-height_25 cs-height_lg_25" />
                          <div className="cs-text_box_text">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Aenean commodo ligula eget dolor. Aenean
                            massa. Cum sociis natoque penatibus et magnis dis
                            parturient montes, nascetur ridiculus mus. Donec
                            quam felis, ultricies nec, pellentesque eu, pretium
                            quis, sem. Nulla consequat massa quis enim. Donec
                            pede justo, fringilla vel, aliquet nec, vulputate
                            eget, arcu. In enim justo, rhoncus ut, imperdiet a,
                            venenatis vitae, justo. Nullam dictum felis eu pede
                            mollis pretium.
                          </div>
                          <div className="cs-height_35 cs-height_lg_35" />
                          <div className="cs-text_box_footer">
                            <a
                              href="about.html"
                              className="cs-btn cs-style2 cs-btn_lg cs-accent_bg cs-medium rounded-0 cs-primary_font"
                            >
                              Learn More
                            </a>
                            <div className="cs-social_btns cs-style1 cs-type1">
                              <a
                                href="#"
                                className="cs-center cs-white_bg cs-accent_color cs-white_hover cs-accent_bg_hover"
                              >
                                <i className="fab fa-facebook-f" />
                              </a>
                              <a
                                href="#"
                                className="cs-center cs-white_bg cs-accent_color cs-white_hover cs-accent_bg_hover"
                              >
                                <i className="fab fa-twitter" />
                              </a>
                              <a
                                href="#"
                                className="cs-center cs-white_bg cs-accent_color cs-white_hover cs-accent_bg_hover"
                              >
                                <i className="fab fa-instagram" />
                              </a>
                              <a
                                href="#"
                                className="cs-center cs-white_bg cs-accent_color cs-white_hover cs-accent_bg_hover"
                              >
                                <i className="fab fa-pinterest-p" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* .cs-tab */}
                      <div
                        id="tab_two"
                        className={
                          about_active_tab_status === "skills"
                            ? "cs-tab active"
                            : "cs-tab"
                        }
                      >
                        <div className="cs-text_box cs-style1 cs-size1">
                          <h2 className="cs-text_box_title cs-medium">
                            Take a look with my <br />
                            <b className="cs-bold cs-accent_color">
                              Skill &amp; Ability
                            </b>
                          </h2>
                          <div className="cs-height_25 cs-height_lg_25" />
                          <div className="cs-text_box_text">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Aenean commodo ligula eget dolor. Aenean massa
                            sociis.
                          </div>
                          <div className="cs-height_30 cs-height_lg_30" />
                          <div className="cs-progressbar_wrap cs-style1">
                            <div className="cs-progressbar cs-style1">
                              <div className="cs-progressbar_head">
                                <h3 className="cs-progressbar_text cs-secondary_color text-uppercase">
                                  Web Design
                                </h3>
                                <div className="cs-progressbar_percentage">
                                  80%
                                </div>
                              </div>
                              <div
                                className="cs-progress cs-accent_10_bg"
                                data-progress={80}
                              >
                                <div className="cs-progress_in cs-accent_bg" />
                              </div>
                            </div>
                            {/* .cs-progressbar */}
                            <div className="cs-progressbar cs-style1">
                              <div className="cs-progressbar_head">
                                <h3 className="cs-progressbar_text cs-secondary_color text-uppercase">
                                  Web development
                                </h3>
                                <div className="cs-progressbar_percentage">
                                  90%
                                </div>
                              </div>
                              <div
                                className="cs-progress cs-accent_10_bg"
                                data-progress={90}
                              >
                                <div className="cs-progress_in cs-accent_bg" />
                              </div>
                            </div>
                            {/* .cs-progressbar */}
                            <div className="cs-progressbar cs-style1">
                              <div className="cs-progressbar_head">
                                <h3 className="cs-progressbar_text cs-secondary_color text-uppercase">
                                  Branding
                                </h3>
                                <div className="cs-progressbar_percentage">
                                  70%
                                </div>
                              </div>
                              <div
                                className="cs-progress cs-accent_10_bg"
                                data-progress={70}
                              >
                                <div className="cs-progress_in cs-accent_bg" />
                              </div>
                            </div>
                            {/* .cs-progressbar */}
                            <div className="cs-progressbar cs-style1">
                              <div className="cs-progressbar_head">
                                <h3 className="cs-progressbar_text cs-secondary_color text-uppercase">
                                  Communication
                                </h3>
                                <div className="cs-progressbar_percentage">
                                  100%
                                </div>
                              </div>
                              <div
                                className="cs-progress cs-accent_10_bg"
                                data-progress={100}
                              >
                                <div className="cs-progress_in cs-accent_bg" />
                              </div>
                            </div>
                            {/* .cs-progressbar */}
                          </div>
                        </div>
                      </div>
                      {/* .cs-tab */}
                      <div
                        id="tab_three"
                        className={
                          about_active_tab_status === "education"
                            ? "cs-tab active"
                            : "cs-tab"
                        }
                      >
                        <div className="cs-text_box cs-style1 cs-size1">
                          <h2 className="cs-text_box_title cs-medium">
                            Complete my study
                            <br /> with{" "}
                            <b className="cs-bold cs-accent_color">
                              good result
                            </b>
                          </h2>
                          <div className="cs-height_25 cs-height_lg_25" />
                          <div className="cs-text_box_text">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Aenean commodo ligula eget dolor. Aenean
                            massa. Cum sociis natoque penatibus et magnis dis
                            parturient montes. <br />
                            <br />
                            Donec pede justo, fringilla vel, aliquet nec,
                            vulputate eget, arcu. In enim justo, rhoncus ut,
                            imperdiet a, venenatis vitae, justo. Nullam dictum
                            felis eu pede mollis pretium. <br />
                            <br />
                            Nascetur ridiculus mus. Donec quam felis, ultricies
                            nec, pellentesque eu, pretium quis, sem. Nulla
                            consequat massa quis enim.
                          </div>
                        </div>
                      </div>
                      {/* .cs-tab */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* .col */}
          </div>
        </div>
        <div className="cs-height_140 cs-height_lg_80" />
      </div>
      {/* End About */}
      {/* Start Service */}
      {/* Check if user give permission to show services */}
      {client?.show_services === 1 ? (
        <div ref={serviceRef}>
          <div className="cs-height_140 cs-height_lg_80" />
          <div
            className="container wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <div className="cs-section_heading cs-style2 cs-size1 text-center">
              <div className="cs-section_subtitle">What can i do</div>
              <h2 className="cs-section_title cs-medium">
                <b className="cs-bold cs-accent_color">Services</b> I can
                provide
              </h2>
            </div>
            <div className="cs-height_70 cs-height_lg_50" />
          </div>
          <div
            className="container wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div className="row">
              {client?.show_services === 1
                ? client.service.map((serv: any) => {
                    return (
                      <div
                        className={
                          //check services length and resize components
                          client.service.length === 3
                            ? "col-lg-4"
                            : client.service.length === 2
                            ? "col-lg-6"
                            : "col-lg-12"
                        }
                      >
                        <div className="cs-icon_box cs-style3 cs-type1">
                          <h2 className="cs-icon_box_title">
                            {serv.title}
                            <span className="cs-title_seperator cs-accent_bg_2" />
                          </h2>
                          <div className="cs-icon_box_icon cs-center">
                            <svg
                              width={112}
                              height={107}
                              viewBox="0 0 112 107"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.6 6.1H13C12.5 6.1 12 5.7 12 5.1C12 4.5 12.4 4.1 13 4.1H20.7C21.2 4.1 21.7 4.5 21.7 5.1C21.7 5.7 21.2 6.1 20.6 6.1Z"
                                fill="#FFA61A"
                              />
                              <path
                                d="M16.8 10C16.3 10 15.8 9.6 15.8 9V1.4C15.8 0.9 16.2 0.4 16.8 0.4C17.4 0.4 17.8 0.8 17.8 1.4V9C17.8 9.5 17.3 10 16.8 10Z"
                                fill="#FFA61A"
                              />
                              <path
                                d="M9.2 14.8H1.5C1 14.8 0.5 14.4 0.5 13.8C0.5 13.2 0.9 12.8 1.5 12.8H9.2C9.7 12.8 10.2 13.2 10.2 13.8C10.2 14.4 9.7 14.8 9.2 14.8Z"
                                fill="#0590FF"
                              />
                              <path
                                d="M5.3 18.6C4.8 18.6 4.3 18.2 4.3 17.6V10C4.3 9.5 4.7 9 5.3 9C5.9 9 6.3 9.4 6.3 10V17.7C6.3 18.2 5.9 18.6 5.3 18.6Z"
                                fill="#0590FF"
                              />
                              <path
                                d="M110.6 30.1H13C12.5 30.1 12 29.7 12 29.1V18.6C12 15.4 14.6 12.9 17.7 12.9H105.7C108.9 12.9 111.4 15.5 111.4 18.6V29.1C111.5 29.6 111.1 30.1 110.6 30.1ZM13.9 28.2H109.6V18.6C109.6 16.5 107.9 14.8 105.8 14.8H17.8C15.7 14.8 14 16.5 14 18.6V28.2H13.9Z"
                                fill="#003B5B"
                              />
                              <path
                                d="M19.7 25.3C17.6 25.3 15.9 23.6 15.9 21.5C15.9 19.4 17.6 17.7 19.7 17.7C21.8 17.7 23.5 19.4 23.5 21.5C23.5 23.6 21.8 25.3 19.7 25.3ZM19.7 19.5C18.6 19.5 17.8 20.4 17.8 21.4C17.8 22.4 18.7 23.3 19.7 23.3C20.7 23.3 21.6 22.4 21.6 21.4C21.6 20.4 20.7 19.5 19.7 19.5Z"
                                fill="#FFA61A"
                              />
                              <path
                                d="M29.3 25.3C27.2 25.3 25.5 23.6 25.5 21.5C25.5 19.4 27.2 17.7 29.3 17.7C31.4 17.7 33.1 19.4 33.1 21.5C33.1 23.6 31.4 25.3 29.3 25.3ZM29.3 19.5C28.2 19.5 27.4 20.4 27.4 21.4C27.4 22.4 28.3 23.3 29.3 23.3C30.3 23.3 31.2 22.4 31.2 21.4C31.2 20.4 30.3 19.5 29.3 19.5Z"
                                fill="#FFA61A"
                              />
                              <path
                                d="M24.5 106.6H20.7C20.2 106.6 19.7 106.2 19.7 105.6C19.7 105 20.1 104.6 20.7 104.6H24.5C25 104.6 25.5 105 25.5 105.6C25.5 106.2 25 106.6 24.5 106.6Z"
                                fill="#003B5B"
                              />
                              <path
                                d="M66.6 106.6H28.3C27.8 106.6 27.3 106.2 27.3 105.6C27.3 105 27.7 104.6 28.3 104.6H66.6C67.1 104.6 67.6 105 67.6 105.6C67.6 106.2 67.1 106.6 66.6 106.6Z"
                                fill="#003B5B"
                              />
                              <path
                                d="M102.9 7.1H99.1C98.6 7.1 98.1 6.7 98.1 6.1C98.1 5.6 98.5 5.1 99.1 5.1H102.9C103.4 5.1 103.9 5.5 103.9 6.1C103.9 6.7 103.5 7.1 102.9 7.1Z"
                                fill="#003B5B"
                              />
                              <path
                                d="M95.3 7.1H57C56.5 7.1 56 6.7 56 6.1C56 5.6 56.4 5.1 57 5.1H95.3C95.8 5.1 96.3 5.5 96.3 6.1C96.2 6.7 95.8 7.1 95.3 7.1Z"
                                fill="#003B5B"
                              />
                              <path
                                d="M96.6 99H17.8C14.6 99 12.1 96.4 12.1 93.3V29.1C12.1 28.6 12.5 28.1 13.1 28.1H110.7C111.2 28.1 111.7 28.5 111.7 29.1V85C111.7 85.5 111.3 86 110.7 86C110.2 86 109.7 85.6 109.7 85V30.1H13.9V93.3C13.9 95.4 15.6 97.1 17.7 97.1H96.5C97 97.1 97.5 97.5 97.5 98.1C97.5 98.7 97.1 99 96.6 99Z"
                                fill="#003B5B"
                              />
                              <path
                                d="M90.5 34.9H33.1V54H90.5V34.9Z"
                                fill="#0590FF"
                                stroke="#003B5B"
                                strokeMiterlimit={10}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M90.5 54.9H33.1C32.6 54.9 32.1 54.5 32.1 53.9V34.9C32.1 34.4 32.5 33.9 33.1 33.9H90.5C91 33.9 91.5 34.3 91.5 34.9V54C91.5 54.5 91 54.9 90.5 54.9ZM34 53H89.5V35.8H34V53Z"
                                fill="#003B5B"
                              />
                              <path
                                d="M27.3 50.2C27.1 50.2 26.8 50.1 26.6 49.9L21.8 45.1C21.4 44.7 21.4 44.1 21.8 43.7L26.6 38.9C27 38.5 27.6 38.5 28 38.9C28.4 39.3 28.4 39.9 28 40.3L23.9 44.4L28 48.5C28.4 48.9 28.4 49.5 28 49.9C27.8 50.1 27.6 50.2 27.3 50.2Z"
                                fill="#0590FF"
                              />
                              <path
                                d="M96.2 50.2C96 50.2 95.7 50.1 95.5 49.9C95.1 49.5 95.1 48.9 95.5 48.5L99.6 44.4L95.5 40.3C95.1 39.9 95.1 39.3 95.5 38.9C95.9 38.5 96.5 38.5 96.9 38.9L101.7 43.7C102.1 44.1 102.1 44.7 101.7 45.1L96.9 49.9C96.7 50.1 96.5 50.2 96.2 50.2Z"
                                fill="#0590FF"
                              />
                              <path
                                d="M48.4 61.6H33.1V76.9H48.4V61.6Z"
                                fill="#FFA61A"
                              />
                              <path
                                d="M48.4 77.9H33.1C32.6 77.9 32.1 77.5 32.1 76.9V61.6C32.1 61.1 32.5 60.6 33.1 60.6H48.4C48.9 60.6 49.4 61 49.4 61.6V77C49.3 77.5 48.9 77.9 48.4 77.9ZM34 76H47.4V62.6H34V76Z"
                                fill="#003B5B"
                              />
                              <path
                                d="M67.7 62.6H53.3C52.8 62.6 52.3 62.2 52.3 61.6C52.3 61 52.7 60.6 53.3 60.6H67.7C68.2 60.6 68.7 61 68.7 61.6C68.7 62.2 68.2 62.6 67.7 62.6Z"
                                fill="#0590FF"
                              />
                              <g opacity="0.4">
                                <path
                                  opacity="0.4"
                                  d="M67.7 70.3H53.3C52.8 70.3 52.3 69.9 52.3 69.3C52.3 68.8 52.7 68.3 53.3 68.3H67.7C68.2 68.3 68.7 68.7 68.7 69.3C68.6 69.8 68.2 70.3 67.7 70.3Z"
                                  fill="#0590FF"
                                />
                              </g>
                              <path
                                d="M67.7 77.9H53.3C52.8 77.9 52.3 77.5 52.3 76.9C52.3 76.4 52.7 75.9 53.3 75.9H67.7C68.2 75.9 68.7 76.3 68.7 76.9C68.6 77.5 68.2 77.9 67.7 77.9Z"
                                fill="#0590FF"
                              />
                              <path
                                d="M67.5 95.1C67 95.1 66.5 94.7 66.5 94.1V85.5H34V94.1C34 94.6 33.6 95.1 33 95.1C32.5 95.1 32 94.7 32 94.1V84.5C32 84 32.4 83.5 33 83.5H67.4C67.9 83.5 68.4 83.9 68.4 84.5V94.1C68.5 94.7 68.1 95.1 67.5 95.1Z"
                                fill="#003B5B"
                              />
                              <path
                                d="M97.3 83.7V93.2L100 90.4L106 96.4L109.9 92.4L104 86.5L106.8 83.7H97.3Z"
                                fill="#0590FF"
                                stroke="#003B5B"
                                strokeMiterlimit={10}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M106 96.9C105.9 96.9 105.7 96.8 105.6 96.7L100.1 91.2L97.7 93.6C97.5 93.8 97.3 93.8 97.1 93.7C96.9 93.6 96.8 93.4 96.8 93.2V83.7C96.8 83.4 97.1 83.1 97.4 83.1H106.9C107.1 83.1 107.3 83.2 107.4 83.4C107.5 83.6 107.4 83.9 107.3 84L104.9 86.4L110.4 91.9C110.6 92.1 110.6 92.5 110.4 92.7L106.4 96.7C106.3 96.9 106.1 96.9 106 96.9Z"
                                fill="#FFA61A"
                                stroke="#003B5B"
                                strokeMiterlimit={10}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M89.6 92.7H75.9C75.4 92.7 75 92.3 75 91.7V61.1C75 60.6 75.4 60.1 75.9 60.1H89.5C90 60.1 90.4 60.5 90.4 61.1V91.7C90.4 92.3 90 92.7 89.6 92.7ZM76.8 90.8H88.7V62.1H76.8V90.8Z"
                                fill="#003B5B"
                              />
                            </svg>

                            <div className="cs-con_box-shape cs-accent_bg" />
                          </div>
                          <div className="cs-icon_box_subtitle">
                            {serv.description}
                          </div>
                        </div>
                        <div className="cs-height_30 cs-height_lg_30" />
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
          <div className="cs-height_110 cs-height_lg_50" />
        </div>
      ) : (
        ""
      )}
      {/* End Service */}
      {/* Start Portfolio */}
      <div className="cs-gradient_bg_2">
        <div className="cs-height_140 cs-height_lg_80" />
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="cs-height_75 cs-height_lg_0" />
              <div className="cs-text_box cs-style1 cs-size1">
                <div className="cs-text_box_subtitle">My portfolio</div>
                <h2 className="cs-text_box_title">
                  My featured{" "}
                  <b className="cs-extra_bold cs-accent_color">works</b> <br />
                  and{" "}
                  <b className="cs-extra_bold cs-accent_color">case studies</b>
                </h2>
                <div className="cs-height_25 cs-height_lg_25" />
                <div className="cs-text_box_text">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                  natoque penatibus et magnis dis parturient montes, nascetur
                  ridiculus mus. Donec quam felis, ultricies nec, pellentesque
                  eu, pretium quis, sem.
                </div>
                <div className="cs-height_35 cs-height_lg_35" />
                <div className="cs-text_box_btn">
                  <a
                    href="portfolio.html"
                    className="cs-btn cs-style2 cs-btn_lg cs-accent_bg_2 cs-medium rounded-0 cs-primary_font"
                  >
                    View More Projects
                  </a>
                </div>
              </div>
              <div className="cs-height_0 cs-height_lg_50" />
            </div>
            {/* .col */}
            <div
              className="col-lg-6 offset-lg-1 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <a
                href="portfolio-details.html"
                className="cs-portfolio cs-style2 cs-left-space"
              >
                <div className="cs-portfolio_thumb cs-bg" data-src={Project1} />
                <div className="cs-portfolio_info">
                  <h2 className="cs-portfolio_title">App Development</h2>
                  <div className="cs-portfolio_subtitle">
                    Design &amp; Development
                  </div>
                </div>
              </a>
              <div className="cs-height_50 cs-height_lg_30" />
            </div>
            {/* .col */}
            <div
              className="col-lg-6 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <a
                href="portfolio-details.html"
                className="cs-portfolio cs-style2 cs-right-space"
              >
                <div
                  className="cs-portfolio_thumb cs-bg"
                  data-src="../assets/img/personal-portfolio/project2.jpg"
                />
                <div className="cs-portfolio_info">
                  <h2 className="cs-portfolio_title">App Development</h2>
                  <div className="cs-portfolio_subtitle">
                    Design &amp; Development
                  </div>
                </div>
              </a>
            </div>
            {/* .col */}
            <div
              className="col-lg-6 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <div className="cs-height_50 cs-height_lg_30" />
              <a
                href="portfolio-details.html"
                className="cs-portfolio cs-style2 cs-left-space"
              >
                <div
                  className="cs-portfolio_thumb cs-bg"
                  data-src="../assets/img/personal-portfolio/project3.jpg"
                />
                <div className="cs-portfolio_info">
                  <h2 className="cs-portfolio_title">App Development</h2>
                  <div className="cs-portfolio_subtitle">
                    Design &amp; Development
                  </div>
                </div>
              </a>
            </div>
            {/* .col */}
          </div>
        </div>
        <div className="cs-height_140 cs-height_lg_80" />
      </div>
      {/* End Portfolio */}
      <div className="cs-height_140 cs-height_lg_80"></div>
      <div
        className="container wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="cs-height_30 cs-height_lg_0"></div>
        <div className="cs-section_heading cs-style2 cs-size1">
          <div className="cs-section_subtitle">Clients feedback</div>
          <h2 className="cs-section_title cs-medium">
            What client <b className="cs-bold cs-accent_color">says</b>
          </h2>
        </div>
        <div className="cs-height_70 cs-height_lg_50"></div>
      </div>
      <div
        className="container wow fadeIn"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="cs-testimonial5_wrap">
          <div
            className="cs-testimonial_thumb cs-bg"
            data-src="../assets/img/personal-portfolio/testimonial-thumb.jpg"
          />
          <div className="cs-slider cs-style1 cs-testimonial_slider_style2">
            <div
              className="cs-slider_container"
              data-autoplay={0}
              data-loop={1}
              data-speed={600}
              data-center={0}
              data-fade-slide={0}
              data-slides-per-view={1}
            >
              <div className="cs-slider_wrapper">
                <div className="cs-slide">
                  <div className="cs-testimonial cs-style5">
                    <div className="cs-testimonial_icon cs-accent_color" />
                    <div className="cs-testimonial_text">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                      nascetur.
                    </div>
                    <div className="cs-testimonial_meta">
                      <div className="cs-testimonial_ratings">
                        <div className="cs-review" data-review="4.5">
                          <div className="cs-review_in" />
                        </div>
                      </div>
                      <h3 className="cs-testimonial_avatar_name">
                        - Jonaathon Doe
                      </h3>
                    </div>
                  </div>
                </div>
                {/* .cs-slide */}
                <div className="cs-slide">
                  <div className="cs-testimonial cs-style5">
                    <div className="cs-testimonial_icon cs-accent_color" />
                    <div className="cs-testimonial_text">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                      nascetur.
                    </div>
                    <div className="cs-testimonial_meta">
                      <div className="cs-testimonial_ratings">
                        <div className="cs-review" data-review="4.5">
                          <div className="cs-review_in" />
                        </div>
                      </div>
                      <h3 className="cs-testimonial_avatar_name">
                        - Jonaathon Doe
                      </h3>
                    </div>
                  </div>
                </div>
                {/* .cs-slide */}
                <div className="cs-slide">
                  <div className="cs-testimonial cs-style5">
                    <div className="cs-testimonial_icon cs-accent_color" />
                    <div className="cs-testimonial_text">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                      nascetur.
                    </div>
                    <div className="cs-testimonial_meta">
                      <div className="cs-testimonial_ratings">
                        <div className="cs-review" data-review="4.5">
                          <div className="cs-review_in" />
                        </div>
                      </div>
                      <h3 className="cs-testimonial_avatar_name">
                        - Jonaathon Doe
                      </h3>
                    </div>
                  </div>
                </div>
                {/* .cs-slide */}
              </div>
            </div>
            {/* .cs-slider_container */}
            <div className="cs-pagination cs-style1 cs-hidden" />
            <div className="cs-slider_arrows cs-style1 cs-type1 cs-center">
              <div className="cs-left_arrow cs-center cs-accent_color">
                <i className="fas fa-angle-left" />
              </div>
              <div className="cs-right_arrow cs-center cs-accent_color">
                <i className="fas fa-angle-right" />
              </div>
            </div>
          </div>
          {/* .cs-slider */}
        </div>
      </div>

      <div className="cs-height_140 cs-height_lg_80" />
      {/* Start Blog Post */}
      <div className="cs-gradient_bg_1">
        <div className="cs-height_135 cs-height_lg_75" />
        <div className="container">
          <div className="row">
            <div
              className="col-xl-4 col-lg-5 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="cs-section_heading cs-style2 cs-size1">
                <div className="cs-section_subtitle">Our blog</div>
                <h2 className="cs-section_title cs-medium">
                  Our new <b className="cs-bold cs-accent_color">article</b> and{" "}
                  <b className="cs-bold cs-accent_color">stories</b>
                </h2>
              </div>
              <div className="cs-height_70 cs-height_lg_50" />
            </div>
            {/* .col */}
            <div
              className="col-xl-7 offset-xl-1 col-lg-7 wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="cs-post6_wrap">
                <div className="cs-post cs-style6">
                  <div className="cs-post_date">26 Jan</div>
                  <h2 className="cs-post_title">
                    <a href="blog-details.html">
                      20 most interesting UX design case studies to inspire you.
                    </a>
                  </h2>
                  <a href="#" className="cs-post_btn" />
                </div>
                <div className="cs-post cs-style6">
                  <div className="cs-post_date">10 Mar</div>
                  <h2 className="cs-post_title">
                    <a href="blog-details.html">
                      Interesting things of web design
                    </a>
                  </h2>
                  <a href="#" className="cs-post_btn" />
                </div>
                <div className="cs-post cs-style6">
                  <div className="cs-post_date">20 Jun</div>
                  <h2 className="cs-post_title">
                    <a href="blog-details.html">
                      How to design simple, minimal attractive website?{" "}
                    </a>
                  </h2>
                  <a href="#" className="cs-post_btn" />
                </div>
              </div>
            </div>
            {/* .col */}
          </div>
        </div>
        <div className="cs-height_130 cs-height_lg_80" />
      </div>
      {/* End Blog Post */}
      <div className="cs-height_135 cs-height_lg_75" />
      <div
        className="container wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="cs-section_heading cs-style2 cs-size1">
          <div className="cs-section_subtitle">Happy client</div>
          <h2 className="cs-section_title cs-medium">
            My beloved <b className="cs-bold cs-accent_color">clients</b>
          </h2>
        </div>
        <div className="cs-height_70 cs-height_lg_50" />
      </div>
      <div
        className="container wow fadeIn"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div className="cs-logo_carousel2_wrap">
          <div className="cs-logo_carousel cs-style2 cs-center">
            <img
              src="../assets/img/personal-portfolio/client-logo1.png"
              alt="Client Logo"
            />
          </div>
          <div className="cs-logo_carousel cs-style2 cs-center">
            <img
              src="../assets/img/personal-portfolio/client-logo2.png"
              alt="Client Logo"
            />
          </div>
          <div className="cs-logo_carousel cs-style2 cs-center">
            <img
              src="../assets/img/personal-portfolio/client-logo3.png"
              alt="Client Logo"
            />
          </div>
          <div className="cs-logo_carousel cs-style2 cs-center">
            <img
              src="../assets/img/personal-portfolio/client-logo4.png"
              alt="Client Logo"
            />
          </div>
          <div className="cs-logo_carousel cs-style2 cs-center">
            <img
              src="../assets/img/personal-portfolio/client-logo5.png"
              alt="Client Logo"
            />
          </div>
          <div className="cs-logo_carousel cs-style2 cs-center">
            <img
              src="../assets/img/personal-portfolio/client-logo6.png"
              alt="Client Logo"
            />
          </div>
          <div className="cs-logo_carousel cs-style2 cs-center">
            <img
              src="../assets/img/personal-portfolio/client-logo7.png"
              alt="Client Logo"
            />
          </div>
          <div className="cs-logo_carousel cs-style2 cs-center">
            <img
              src="../assets/img/personal-portfolio/client-logo8.png"
              alt="Client Logo"
            />
          </div>
        </div>
      </div>
      <div className="cs-height_140 cs-height_lg_80" />
      {/* Footer Section */}
      <footer className="cs-footer cs-style2 cs-accent_15_bg_2">
        <div className="container">
          <div className="cs-cta cs-style2">
            <div className="cs-cta_text">
              <div className="cs-cta_subtitle cs-normal">Any project idea?</div>
              <h2 className="cs-cta_title">
                Let’s <b className="cs-bold">Talk?</b>
              </h2>
            </div>
            <div className="cs-cta_btn">
              <a
                href="contact.html"
                className="cs-btn cs-style2 cs-btn_lg cs-accent_bg cs-medium rounded-0 cs-primary_font"
              >
                Let’s Talk
              </a>
            </div>
          </div>
        </div>
        <div className="cs-copyright cs-accent_7_bg_2">
          <div className="container">
            <div className="cs-copyright_in">
              © 2022. All rights reserved by Multim.
            </div>
            <div className="cs-footer_item widget_nav_menu">
              <ul className="menu">
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>
                  <a href="about.html">About</a>
                </li>
                <li>
                  <a href="service.html">Service</a>
                </li>
                <li>
                  <a href="portfolio.html">Portfolio</a>
                </li>
                <li>
                  <a href="blog.html">Blog</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Section */}
      {/* Start Video Popup */}
      <div className="cs-video_popup">
        <div className="cs-video_popup_overlay" />
        <div className="cs-video_popup_content">
          <div className="cs-video_popup_layer" />
          <div className="cs-video_popup_container">
            <div className="cs-video_popup_align">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" src="about:blank" />
              </div>
            </div>
            <div className="cs-video_popup_close" />
          </div>
        </div>
      </div>
      {/* End Video Popup */}
    </div>
  );
};

export default PersonalPortfolio;
